import React, { useEffect } from "react";

//goes to the top of the page when the button is clicked
const ToTop = React.forwardRef((props, divRefs) => {
  const { home } = divRefs;

  const toTop = () => {
    home.current.scrollIntoView();
  };

  const toggleVisibility = () => {
    let btn = document.querySelector(".button-scroller");
    if (window.scrollY > window.innerHeight * 0.9) {
      btn.classList.add("btn-active");
    } else {
      btn.classList.remove("btn-active");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <button className="button-scroller" onClick={toTop}>
      <a href="#">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="white"
        >
          <path d="M6 4h12v2H6zm5 10v6h2v-6h5l-6-6-6 6z"></path>
        </svg>
      </a>
    </button>
  );
});

export default ToTop;
