import "./constants/styles.css";
import React, { useEffect } from "react";
import About from "./component/Sections/about/About";
import ToTop from "./component/UI/ToTop";
import Home from "./component/Sections/home/Home";
import Projects from "./component/Sections/projects/Projects";

function App() {
  const about = React.useRef(null);
  const projects = React.useRef(null);
  const career = React.useRef(null);
  const home = React.useRef(null);
  const divRefs = { home, about, career, projects };

  return (
    <div className="app">
      <section ref={home} className="app-page main-page">
        <Home ref={divRefs} />
      </section>
      <section ref={about} className="app-page">
        <About />
      </section>

      <section ref={projects} className="app-page fire">
        <Projects />
      </section>
      <ToTop ref={divRefs} />
    </div>
  );
}

export default App;
