import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import projects from "../../../constants/projects";
import Card from "./Card";

const showCards = () => {
  return projects.map((project, index) => {
    return <Card key={index} project={project} />;
  });
};

export default function Projects() {
  const [t, i18n] = useTranslation("global");

  return (
    <section className="projects-wrapper">
      <div className="projects-wrapper-title hidden">
        <h1>{t("projects.projects-title")}</h1>
        <p>{t("projects.projects-paragraph")}</p>
      </div>
      <div className="card-wrapper hidden">{showCards()}</div>;
    </section>
  );
}
