import React, { useEffect } from "react";
import HomeBody from "./HomeBody";
import Social from "./Social";
import NavBar from "./NavBar";
import "../../../constants/styles.css";
import mainBackground from "../../../Assets/Images/mainBackground.jpg";
import { paralaxDown } from "../../../functions/paralax";

const BackGround = "mainbody-background";

const bgParalax = () => {
  let rate = 0.3;
  paralaxDown(BackGround, rate);
};

const Home = React.forwardRef((props, divRefs) => {
  useEffect(() => {
    window.addEventListener("scroll", bgParalax);
    return () => {
      window.removeEventListener("scroll", bgParalax);
    };
  }, []);

  return (
    <>
      <div
        className={BackGround}
        style={{ backgroundImage: `url(${mainBackground})` }}
      ></div>
      <NavBar ref={divRefs} />
      <HomeBody />
      <Social />
    </>
  );
});

export default Home;
