import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobile } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { revealClasses } from "../../../functions/reveal";
import CV from "../../../Assets/docs/CV.pdf";

export default function Contact() {
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    revealClasses();
  }, []);

  return (
    <section className="about-content hidden">
      <h1 className="about-content-title ">{t("about.contact")}</h1>
      <span className="about-contact">
        <div className="about-contact-item">
          <FontAwesomeIcon
            className="about-icon hvr-bounce-in"
            icon={faMobile}
          />
          <h1 className="contact-item-content">(+94) 011-3603-8696</h1>
        </div>
        <div className="about-contact-item">
          <FontAwesomeIcon
            className="about-icon hvr-bounce-in"
            icon={faEnvelope}
          />
          <h1 className="contact-item-content">znicolasheredia@gmail.com</h1>
        </div>
      </span>

      <a className="about-button" href={CV} download="CV Ariel Nicolas Heredia">
        <FontAwesomeIcon icon={faDownload} />
        {t("about.contact-button")}
      </a>
    </section>
  );
}
