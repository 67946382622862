export const revealClasses = () => {
  const reveal = () => {
    let divsToShow = "hidden";
    var reveals = document.getElementsByClassName(divsToShow);
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight * 0.9;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
        reveals[i].classList.add("animate__animated");
        reveals[i].classList.add("animate__zoomIn");
        reveals[i].classList.add("animate__fast");
      }
    }
  };

  window.addEventListener("scroll", reveal);
  // To check the scroll position on page load

  return () => {
    window.removeEventListener("scroll", reveal);
  };
};
