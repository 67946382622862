import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Portrait from "../../../Assets/Images/portrait.jpg";
import "../../../constants/styles.css";
import { revealClasses } from "../../../functions/reveal";
import Contact from "./Contact";

const reveal = () => {
  revealClasses();
};

export default function About() {
  //get languaje change
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    reveal();
  });

  return (
    <main className="about-wrapper">
      <div className="about-image hidden">
        <img alt="" src={Portrait}></img>
      </div>
      <div className="about-content ">
        <h1 className="about-content-title hidden">{t("about.title")}</h1>
        <p className="hidden">{t("about.paragraph")}</p>
        <div className="divider"></div>
        <Contact />
      </div>
    </main>
  );
}
