import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "../../../constants/cards.css";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { paralaxDown } from "../../../functions/paralax";

export default function Card({ project }) {
  const { title, img, url, git } = project;

  return (
    <section className="card">
      <div className="image">
        <img src={img} alt="" />
      </div>
      <div className="details">
        <div className="center">
          <h1>{title}</h1>
          <ul>
            <li>
              <a href={url}>
                <FontAwesomeIcon className="hvr-bounce-in" icon={faGlobe} />
              </a>
            </li>
            <li>
              <a href={git}>
                <FontAwesomeIcon className="hvr-bounce-in" icon={faGithub} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
