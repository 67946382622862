import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  saveLanguage,
  setButtonInitialLanguage,
} from "../../LocalStorage/langLocalStorage";
import "./../../constants/langbutton.css";

export default function LangButton() {
  const [t, i18n] = useTranslation("global");
  const [checked, setIsChecked] = React.useState(
    i18n.language === "eng" ? true : false
  );

  const handleSwitchLanguage = () => {
    i18n.changeLanguage(i18n.language === "eng" ? "esp" : "eng");
    setIsChecked((prev) => !prev);
    saveLanguage(i18n.language);
  };

  return (
    <div className="lang-button animate__animated animate__bounceIn">
      <div className="switch">
        <input
          onChange={handleSwitchLanguage}
          id="language-toggle"
          className="check-toggle check-toggle-round-flat"
          type="checkbox"
          checked={checked}
        />
        <label htmlFor="language-toggle" />
        <span className="on">ESP</span>
        <span className="off">EN</span>
      </div>
    </div>
  );
}
