import React from "react";
import "../../../constants/styles.css";
import "animate.css";
import { useTranslation } from "react-i18next";

export default function HomeBody() {
  const [t, i18n] = useTranslation("global");
  return (
    <div className="homebody-wrapper animate__animated animate__fadeIn">
      <div className="homebody-container">
        <h1 className="homebody-title">{t("home.homebody.title")} </h1>
        <p>{t("home.homebody.paragraph")}</p>
      </div>
    </div>
  );
}
