const projects = [
  {
    title: "Fashion Model Website",
    img: "https://res.cloudinary.com/segneal/image/upload/q_auto:eco/cld-sample-projectBg_mlou8i",
    url: "http://irenevallejo.com.ar",
    git: "https://github.com/Segneal/jes-web-portfolio",
  },
  {
    title: "Wordle App",
    img: "https://res.cloudinary.com/segneal/image/upload/q_auto:eco/cld-sample_lwy1i7  ",
    url: "https://wordle-nicodev.netlify.app/",
    git: "https://github.com/Segneal/wordle",
  },
];

export default projects;
