import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LangButton from "../../UI/LangButton";

const NavBar = React.forwardRef((props, divRefs) => {
  const { home, about, projects } = divRefs;
  const [t, i18n] = useTranslation("global");

  const scrollToView = (section) => {
    section.current.scrollIntoView();
  };

  return (
    <div
      id="topNav"
      className="topNav animate__animated animate__fadeIn  animate__slow animate__delay-1s"
    >
      <ul className="topNav-ul">
        <li className="list-item">
          <a href="#career" onClick={() => scrollToView(about)}>
            {t("home.navBar.about")}
          </a>
        </li>
        <li className="list-item">
          <a href="#projects" onClick={() => scrollToView(projects)}>
            {t("home.navBar.projects")}
          </a>
        </li>
      </ul>
      <div className="lang-button">
        <LangButton />
      </div>
    </div>
  );
});

export default NavBar;
