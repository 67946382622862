import { Flex, Link } from "@chakra-ui/react";
import React from "react";
import "animate.css";
import Instagram from "../../../Assets/logos/Instagram.png";
import Linkedin from "../../../Assets/logos/Linkedin.png";
import Github from "../../../Assets/logos/Github.png";

const linkedinUrl = "https://www.linkedin.com/in/anicoheredia/";
const instagramUrl = "https://www.instagram.com/arielnicolasheredia/";
const githubUrl = "https://github.com/Segneal";

//displays social media links
export default function Social() {
  return (
    <Flex className="social-media animate__animated animate__fadeIn  animate__slow animate__delay-1s">
      <Link className="social-icons" href={linkedinUrl}>
        <img alt="" src={Linkedin} className="hvr-bounce-in" />
      </Link>
      <Link className="social-icons" href={instagramUrl}>
        <img alt="" src={Instagram} className="hvr-bounce-in" />
      </Link>
      <Link className="social-icons" href={githubUrl}>
        <img alt="" src={Github} className="hvr-bounce-in" />
      </Link>
    </Flex>
  );
}
