import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import theme from "./constants/theme";
import "./constants/styles.css";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_es from "./translations/esp/global.json";
import global_en from "./translations/eng/global.json";
import { initLangStorage } from "./LocalStorage/langLocalStorage";

const initLang = initLangStorage();

i18next.init({
  interpolation: { escapeValue: false },
  lng: initLang,
  resources: {
    esp: {
      global: global_es,
    },
    eng: {
      global: global_en,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </I18nextProvider>
  </React.StrictMode>
);
